<template>
  <v-dialog
    v-model="dialog"
    :max-width="message.dialogMaxWidth || 700"
    persistent
  >
    <v-card>
      <v-responsive>
        <v-card-title
          v-if="message.title"
        >
          <h3 class="font-weight-regular">
            {{ message.title }}
          </h3>
        </v-card-title>
        <v-card-text>
          <div>
            {{ message.text }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-row>
            <v-col
              class="d-flex flex-column justify-space-around"
              cols="12"
            >
              <template v-for="(btn, i) in message.acceptbtns">
                <v-btn
                  v-on:click="accept(btn.value)"
                  :key="i"
                  :color="btn.acceptColor"
                  class="my-1"
                >
                  {{ btn.acceptText }}
                </v-btn>
              </template>
            </v-col>
            <v-col
              class="d-flex justify-center"
              cols="12"
            >
              <v-btn
                v-on:click="cancel"
                :color="message.cancelColor"
              >
                {{ message.cancelText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-responsive>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'mdialog',
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      text: '',
      message: {
        dialogMaxWidth: null,
        acceptbtns: [],
        cancelText: 'отмена',
        acceptColor: 'success',
        cancelColor: 'warning',
        title: '',
        text: '',
      },
    }),
    methods: {
      open (message) {
        for (const item in message) {
          this.message[item] = message[item]
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
          this.dialog = true
        })
      },
      accept (val) {
        this.dialog = false
        this.resolve(val)
      },
      cancel () {
        this.dialog = false
        this.reject()
      },
    },
  }
</script>

<style scoped>

</style>
